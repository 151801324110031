import React from "react";

import { PersonsProps } from "./types";
import "./styles.scss";

const Persons = ({ author, expert }: PersonsProps) =>
  (
    <div className="seo-persons">
      {author && (
      <div itemScope itemProp="author" itemType="http://schema.org/Person" className="seo-article__person">
        <div className="seo-persons__person-title">Автор статьи</div>
        <div className="seo-persons__person-card">
          <div className="seo-persons__person-card-left">
            <img
              src={author.image?.data?.attributes?.url}
              itemProp="image"
              alt={author.name}
            />
          </div>
          <div className="seo-persons__person-card-right">
            <div itemProp="name" className="seo-persons__person-name">
              {author.name}
            </div>
            <div itemProp="description" className="seo-persons__person-description">{author.description}</div>
          </div>
        </div>
      </div>
      )}
      {/* {expert && (
        <div className="seo-persons__person">
          <div className="seo-persons__person-title">Эксперт статьи</div>
          <div className="seo-persons__person-card">
            <div className="seo-persons__person-card-left">
              <img src={expert.smallImage} alt="" />
            </div>
            <div className="seo-persons__person-card-right">
              <div className="seo-persons__person-name">
                <Link to={`/expert/${20}`}>{expert.name}</Link>
              </div>
              <div className="seo-persons__person-info">
                <Rating value={expert.rating} />
                <div className="seo-persons__person-skills">
                  {capitalize(
                    (expert.skills ?? []).map((skill) =>
                      skill.badge)
                      .join(", "),
                  )}
                </div>
              </div>
              <div className="seo-persons__person-status"><ExpertStatus status={expertStatus} /></div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );

export default Persons;
