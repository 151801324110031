import React from "react";

import defaultImage from "@images/seo/default-link-image.jpg";
import defaultImage2x from "@images/seo/default-link-image_@2x.jpg";
import { Link } from "gatsby";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { convertToDateLiteraryShortened } from "@/utils/dateUtils";

import { getReadDurationMinByText, shuffleArrayById } from "../utils";

import { CarouselLinksBlockProps } from "./types";

import "swiper/css";
import "./styles.scss";

const CarouselLinksBlock = ({ autolinkedArticleLinks, urlPrefix, seoId }: CarouselLinksBlockProps) =>
  (
    <div className="carousel-links-block">
      <div className="carousel-links-block__arrow left">
        <Icon type={IconTypeEnum.Left} />
      </div>
      <div className="carousel-links-block__arrow right">
        <Icon type={IconTypeEnum.Right} />
      </div>

      <Swiper
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={16}
        breakpoints={{
          768: {
            slidesPerView: 3.3,
          },
          930: {
            slidesPerView: 4.2,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
        navigation={{
          nextEl: ".carousel-links-block__arrow.right",
          prevEl: ".carousel-links-block__arrow.left",
          disabledClass: "hide",
        }}
        className="carousel-links-block__slider"
      >
        {shuffleArrayById(autolinkedArticleLinks, seoId)?.map((item) => {
          const currentBreadcrumbs = item?.data?.attributes?.breadcrumbs;
          const firstItemBreadcrumbs = currentBreadcrumbs?.length
            ? currentBreadcrumbs[0]
            : null;
          const breadcrumbDisplayName = firstItemBreadcrumbs?.article?.data?.attributes?.breadcrumbDisplayName;
          const minToRead = getReadDurationMinByText(item?.data?.attributes?.text ?? "");

          return (
            <SwiperSlide key={item?.data?.id} className="carousel-links-block__card-container">
              <Link to={`/${urlPrefix}${item?.data?.attributes?.URL}`}>
                <div className="carousel-links-block__card">
                  <picture>
                    <source
                      srcSet={`${item?.data?.attributes?.image?.oneX.data?.attributes?.url ?? defaultImage},
                        ${item?.data?.attributes?.image?.twoX.data?.attributes?.url ?? defaultImage2x} 2x,
                        ${
                          item?.data?.attributes?.image?.threeX?.data?.attributes?.url
                          ?? item?.data?.attributes?.image?.twoX.data?.attributes?.url ?? defaultImage2x
                        }, 3x
                        `}
                      media="(max-width: 300px)"
                    />
                    <img
                      src={
                          item?.data?.attributes?.image?.twoX?.data?.attributes?.url
                            || defaultImage2x
                        }
                      alt=""
                    />
                  </picture>
                </div>
                <div className="carousel-links-block__details-header-block">
                  <p className="carousel-links-block__parent">{breadcrumbDisplayName}</p>
                  <Link to={`/${urlPrefix}${item?.data?.attributes?.URL}`} className="carousel-links-block__title">{item?.data?.attributes?.h1 || ""}</Link>
                </div>
                <div className="carousel-links-block__details">
                  <p className="carousel-links-block__published-date">
                    {convertToDateLiteraryShortened(item?.data?.attributes?.publishedAt)}
                  </p>
                  <div className="carousel-links-block__data-item">
                    <Icon type={IconTypeEnum.Time} size={IconSizeEnum.Size20} />
                    <div>
                      {minToRead}
                      {" "}
                      мин
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );

export default CarouselLinksBlock;
